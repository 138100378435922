<template>
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0 font-size-18">Quản lý Jobs Facebook</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <a href="javascript: void(0);">Admin Traodoicheo</a>
              </li>
              <li class="breadcrumb-item active">Quản lý Jobs</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <user-online-vue />
    <div class="row">
      <div class="col-12">
        <div class="card py-4">
          <div class="container-fluid">
            <div class="row">
              <form @submit.prevent="getJobList" class="col-md-3 mb-4 mb-md-0">
                <div class="form-group">
                  <label for="">Tìm theo Object Id</label>
                  <input type="text" class="form-control" placeholder="nhập object id" v-model="object_id" />
                </div>
              </form>
              <form @submit.prevent="getJobList" class="col-md-3 mb-4 mb-md-0">
                <div class="form-group">
                  <label for="">Tìm theo Job Id</label>
                  <input type="text" class="form-control" placeholder="nhập user id" v-model="job_id" />
                </div>
              </form>
              <form @submit.prevent="getJobList" class="col-md-3 mb-4 mb-md-0">
                <div class="form-group">
                  <label for="">Tìm theo User Id</label>
                  <input type="text" class="form-control" placeholder="nhập user id" v-model="user_id" />
                </div>
              </form>
              <form class="col-md-3 mb-4 mb-md-0">
                <div class="form-group">
                  <label for="">Limit</label>
                  <select class="form-select" v-model="limit" @change="getJobList">
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="1000">1000</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="row">
              <div class="col-12">
                <a @click="getJobList({ is_hidden: 1 })" class="btn btn-danger mt-3" role="button">
                  Load danh sách job ẩn
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-vue v-if="loading" />
    <div class="row">
      <div class="col-md-12">
        <div class="card text-left">
          <img class="card-img-top" src="holder.js/100px180/" alt="" />
          <div class="card-body">
            <h4 class="card-title">Thao tác nhanh</h4>
            <p class="card-text">
              <a @click="openAllJobHidden()" class="btn btn-success mt-2" role="button">
                Mở tất cả job ẩn
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <datatable :stt="true" :title="'Danh sách Jobs Facebook'" :columns="columns" :rows="manipulatedJobs"
          :defaultPerPage="10">
          <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
          <td slot="tbody-tr-o" class="text-center" slot-scope="props">
            <button class="btn btn-primary font-10 py-1 btn-xs me-1" @click="setPriorityJob(props.row)">
              Ưu tiên
            </button>
            <button class="btn btn-danger font-10 py-1 btn-xs" v-if="props.row.is_hidden === 0"
              @click="hideJob(props.row)">
              Ẩn
            </button>
            <button class="btn btn-success font-10 py-1 btn-xs" v-if="props.row.is_hidden === 1"
              @click="unHideJob(props.row)">
              Mở
            </button>
          </td>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import jobApi from "@/api/job";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";
import datetimeHelper from "@/helpers/datetime";
import UserOnlineVue from "@/components/user/UserOnline.vue";

export default {
  name: "JobsFacebook",
  components: {
    Datatable,
    LoadingVue,
    UserOnlineVue,
  },
  data() {
    return {
      job_id: null,
      user_id: null,
      object_id: null,
      limit: 50,
      jobs: [],
      loading: false,
      columns: [
        { label: "User Id", field: "user_id" },
        { label: "Mfb username", field: "mfb_username" },
        { label: "Object Id", field: "object_id", fb_link: true },
        { label: "Hidden", field: "hidden_name" },
        { label: "Orders Id", field: "orders_id" },
        { label: "Giá", field: "price_per" },
        { label: "Số lượng", field: "quantity_html", html: true },
        { label: "Package", field: "package_name" },
        { label: "Type", field: "type" },
        { label: "Status", field: "status_message", html: true },
        { label: "Start", field: "start", numeric: true },
        { label: "Start", field: "start_like", numeric: true },
        { label: "Ngày duyệt", field: "time_payment", timeago: true },
        { label: "Ngày thanh toán", field: "time_payment", timeago: true },
        { label: "Ngày kết thúc chạy", field: "time_end", timeago: true },
        { label: "Số lần đã thành toán", field: "paid", numeric: true },
        { label: "Ngày tạo", field: "created_at", timeago: true },
        { label: "Count data", field: "count_data" },
      ],
    };
  },
  computed: {
    manipulatedJobs() {
      return this.jobs.map((job) => {
        job.hidden_name = this.hiddenName(job.is_hidden);
        if (job.is_hidden === 1 || job.is_hidden === "1") {
          job.color = true;
        } else {
          job.color = false;
        }
        return job;
      });
    },
  },
  created() {
    this.getJobList();
  },
  methods: {
    async openAllJobHidden() {
      for (const job of this.jobs) {
        console.log(job);
        const matchCountJobRun = job.status_message.match(/Bị ẩn - đã chạy (\d+)/);
        console.log(matchCountJobRun);
        if (matchCountJobRun && matchCountJobRun[1] && matchCountJobRun[1] > 0) {
          await datetimeHelper.wait(10);
          await this.unHideJob(job);
        }
      }
    },
    async hideJob(job) {
      const params = {
        id: job.id,
        is_hidden: 1,
      };
      const response = await jobApi.update(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
        this.jobs = this.jobs.map((j) => {
          if (j.id !== job.id) {
            return j;
          } else {
            j.is_hidden = 1;
            return j;
          }
        });
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    async setPriorityJob(job) {
      const params = {
        id: job.id,
      };
      const response = await jobApi.priority(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    async unHideJob(job) {
      const params = {
        id: job.id,
        is_hidden: 0,
      };
      const response = await jobApi.update(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
        this.jobs = this.jobs.map((j) => {
          if (j.id !== job.id) {
            return j;
          } else {
            j.is_hidden = 0;
            return j;
          }
        });
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    hiddenName(id) {
      switch (id) {
        case 1:
          return "ẩn";
        case "1":
          return "ẩn";
        case 0:
          return "mở";
        case "0":
          return "mở";
        default:
          break;
      }
    },
    async getJobList(opts = {}) {
      const params = {
        limit: this.limit,
        user_id: this.user_id || null,
        object_id: this.object_id,
        id: this.job_id || null,
      };
      if (opts.is_hidden != undefined) {
        params.is_hidden = opts.is_hidden;
      }
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await jobApi.list(queries);
      this.loading = false;
      if (response?.success) {
        this.jobs = response.data.map((x) => {
          x.quantity_html = `<span class="text-success">${x.count_is_run}</span> / <span class="text-primary">${x.quantity}</span>`;
          x.status_message = x.status_message.match(/Đang chạy/)
            ? `<span class="text-primary">${x.status_message}</span>`
            : `${x.status_message}`;
          return x;
        });
      } else {
        this.$toastr.error(response?.message || "Get jobs không thành công");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
